<template>
  <div class="mission-info" v-loading='dataListLoading'>
    <div class="info">
      <div class="title">内容信息</div>
      <div class="cnt">
        <el-form :model="form">
          <el-form-item label='问卷类型' >{{form.contentTypeName}}</el-form-item>
          <el-form-item label='问卷名称' >{{form.contentName}}</el-form-item>
          <el-form-item label='关联随访名称' >{{followUpName}}</el-form-item>
          <el-form-item label='发布日期' >{{form.createTime}}</el-form-item>
          <el-form-item label='发布人数' >{{number}}</el-form-item>
          <!-- <el-form-item label='问卷开始填写时间' >{{form.executionTime}}</el-form-item> -->
          <!-- <el-form-item label='问卷要求完成时间' >{{form.endTime}}</el-form-item> -->
          <!-- <el-form-item label='具体内容' >
           <div v-html="form.workContent"></div>
          </el-form-item> -->
        </el-form>
      </div>
    </div>
    <div class="list">
      <div class="title">
        <div class="text">患者信息</div> 
        <el-link  @click='go' target="_blank">查看问卷详情</el-link>
      <!-- <el-button style='float:right;margin-top:5px'>查看详情</el-button> -->
      </div>
        <el-table :data='followUps' border>
          <!-- <el-table-column type='index'></el-table-column> -->
          <el-table-column  label="姓名" prop='followUpUserName'></el-table-column>
          <el-table-column  label="状态" >
            <template slot-scope="scope">
              <div v-if='scope.row.isAnswer===1'>已完成</div>
              <div v-else-if='scope.row.isAnswer===2'>未完成</div>
              <!-- <div v-else-if='scope.row.executionStatus===3'>已结束</div> -->
            </template>
          </el-table-column>
        </el-table>
    </div>
  </div>
</template>
<script>
export default {
  name:'feedBackFollowInfo',
  data(){
    return {
      number:'',
      dataListLoading:false,
      form:{},
      followUps:[],
      href:'',
      followUpName:''
    }
  },
  mounted() {
    let query = this.$route.query
      this.number = query.num
      this.followUpName = query.followUpName
      console.log(this.query,'query');
    if(query.id){
      this.dataListLoading = true
       this.init(query.id)
    }
  },
  methods:{
    go(){
        // this.$router.push({path:'/feedback/followUp/record',query:{id:this.form.contentId}})
        this.$router.push({path:'/dataCollect/info',query:{id:this.form.contentId,activeName:3}})

    },
    async init(id){
      const {data:res} = await this.$httpAes({
        url:this.$httpAes.adornUrl('/followUp/getFollowUpFeedbackByContentId'),
        method:'post',
        data:{
          stringParam2:this.followUpName,
          stringParam1:1
        }
      })
      console.log(res,'res info1111111111111111111111111111111111111111');
      if(res.status){
        this.form = res.data[0]
        // this.href = 'http://www.baidu.com'
        // this.href = 'https://medical.aibiboat.com/h5/dataCollectInfo.html?id=607d821b49b44d0ea11c57b93429d693&infoId=9719be3f3ab24ca1b747edc24afdac6f'
        this.followUps = res.data
        this.dataListLoading = false
      }else{
        this.$message.error('获取失败，请重试')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.mission-info{
  display: flex;
  justify-content: space-between;
  .title{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
    background-color: #e4e4e4;
    text-indent: 20px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

  }
  .info{
    flex-basis: 49%;
    // height: 700px;
    min-height: 700px;
    height: auto;
    box-shadow:0 0 8px 0 rgba(232,237,250,.6),0 2px 4px 0 rgba(232,237,250,.5);
    border-radius: 5px;
    .cnt{
      padding: 20px;
    }
  }
  .list{
    flex-basis: 49%;
    height: auto;
    min-height: 700px;
    box-shadow:0 0 8px 0 rgba(232,237,250,.6),0 2px 4px 0 rgba(232,237,250,.5);
    border-radius: 5px;

  }
  /deep/.el-link.is-underline:hover:after{
    border: none;
  }
}
</style>